import { Injectable } from '@angular/core';

declare const googleTranslateElementInit: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleTranslateService {

  scriptLoaded:boolean = false;

  constructor() { }

  loadScript(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      if (this.scriptLoaded) {
        resolve();
        return;
      }
      const script = document.createElement('script');
      script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
      script.onload = () => {
        this.scriptLoaded = true;
        resolve();
      };
      script.onerror = (error: any) => reject(error);
      document.body.appendChild(script);
    });
  }

  initializeTranslateElement(): void {
    if (!this.scriptLoaded) {
      console.error('Google Translate API script is not loaded.');
      return;
    }
    googleTranslateElementInit();
  }

  // initGoogleTranslate() {
  //   if (typeof googleTranslateElementInit === 'function') {
  //     googleTranslateElementInit();
  //   }
  // }

  // setLanguage(languageCode: string) {

  //   const translateElement = document.getElementById('google_translate_element');
  //   if (translateElement) {
  //     const selectElement = translateElement.querySelector('.goog-te-combo') as HTMLSelectElement;
  //     if (selectElement) {
  //       selectElement.dispatchEvent(new Event('change'));
  //       selectElement.value = languageCode;
  //       selectElement.dispatchEvent(new Event('change'));
  //     }
  //   }
  // }

    // if (typeof googleTranslateElementInit === 'function') {
    //   const translateElement = document.getElementById('google_translate_element');
    //   if (translateElement) {
    //     const selectElement = translateElement.querySelector('.goog-te-combo') as HTMLSelectElement;
    //     selectElement.dispatchEvent(new Event('change'));
    //     selectElement.value = languageCode;
    //     selectElement.dispatchEvent(new Event('change'));
    //   }
    // }

    // const translateElement = document.getElementById('google_translate_element');
    // if (translateElement) {
    //   const selectElement = translateElement.querySelector('.goog-te-combo') as HTMLSelectElement;
    //   selectElement.dispatchEvent(new Event('change'));
    //   selectElement.value = languageCode;
    //   selectElement.dispatchEvent(new Event('change'));
    // }
  
    // setLanguage(languageCode: string) {
    //     // Wait for the translate element to be available in the DOM
    //     const observer = new MutationObserver((mutations) => {
    //       mutations.forEach((mutation) => {
    //         if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
    //           this.updateLanguage(languageCode);
    //           observer.disconnect();
    //         }
    //       });
    //     });
    
    //     const translateElement = document.getElementById('google_translate_element');
    //     if (translateElement) {
    //       observer.observe(translateElement, { childList: true });
    //     }
    //   }
    
    //   private updateLanguage(languageCode: string) {
    //     const translateElement = document.getElementById('google_translate_element');
    //     if (translateElement) {
    //       const selectElement = translateElement.querySelector('.goog-te-combo') as HTMLSelectElement;
    //       if (selectElement) {
    //         selectElement.dispatchEvent(new Event('change'));
    //         selectElement.value = languageCode;
    //         selectElement.dispatchEvent(new Event('change'));
    //       }
    //     }
    //   }
  }


