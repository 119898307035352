import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    // {
    //     id: 1,
    //     label: 'Search',
    //     icon: 'bx-search',
    //     link: '/pages/Search',
    // },
    // {
    //     id: 2,
    //     label: 'Favourites',
    //     icon: 'bx-heart',
    //     link: '/pages/favouritelist',
    // },
    // {
    //     id: 3,
    //     label: 'Contact',
    //     icon: 'bx-phone',
    //     link: '/pages/contactInfo',
    // },
];

