import { ChangeDetectorRef, Component , OnInit} from '@angular/core';
import { GoogleTranslateService } from './core/services/googletranslate.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {

constructor(private googleTranslateService: GoogleTranslateService, private router: Router, private cdr: ChangeDetectorRef){}

  ngOnInit() {
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Check if the current route is not the login page
        if (event.url !== '/login') {
          this.removeGoogleTranslator();
          // Load Google Translator script
          this.loadGoogleTranslatorScript();
        } else {
          // Remove Google Translator if already loaded
          this.removeGoogleTranslator();
        }
      }
    });
  }

  loadGoogleTranslatorScript() {
    this.cdr.detectChanges();
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  removeGoogleTranslator() {
    const googleTranslateElement = document.getElementById('google_translate_element');
    if (googleTranslateElement) {
      googleTranslateElement.innerHTML = '';
    }
  }

}
