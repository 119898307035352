<footer class="footer">
    <div>
        <div class="row">
            <div class="col-sm-6">
                {{year}} © Skote.
            </div>
            <div class="col-sm-6">
                <!-- <div class="text-sm-end d-none d-sm-block">
                    Design & Develop by Themesbrand
                </div> -->
                <div class="text-end">
                    <img src="/assets/images/Trialingologopreview.png" height="40">
                </div> 
            </div>
        </div>
    </div>
</footer>